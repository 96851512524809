<template>
    <header
        class="the-hero"
        :class="[theme, themeVariant]"
    >
        <div
            class="the-hero__container"
            :class="width < 768 ? [items[items.length - 1].selectedTheme, themeVariant] : [themeFirstItem, themeVariant]"
        >
            <h1>
                <TheLogo class="the-hero_logo" />
            </h1>

            <div
                class="the-hero__content"
            >
                <BaseImage
                    class="the-hero_image-desktop"
                    :image="themeImage"
                    sizes="(max-width: 900px) 100vw, 800px"
                    fetchpriority="high"
                    loading="eager"
                />

                <div
                    :class="colorLastItem"
                    class="the-hero__info"
                >
                    <div>
                        <div
                            v-for="(item, index) in items"
                            :key="index"
                            class="the-hero__item"
                            :class="[item?.selectedTheme, themeVariant]"
                            @mouseover="changeThemeHero(item)"
                            @mouseout="changeThemeHero(items?.[0])"
                        >
                            <BaseImage
                                class="the-hero_image-mobile"
                                :image="item?.heroImage?.[0]"
                                fetchpriority="high"
                                loading="eager"
                            />
                            <div class="the-hero__link">
                                <nuxt-link
                                    :to="item?.uri"
                                >
                                    <div class="the-hero__link-category">{{ item?.heroLabel }}</div>
                                    <div class="the-hero__link-title">{{ item?.heroTitle }}</div>
                                </nuxt-link>
                            </div>
                        </div>
                    </div>

                    <div
                        class="the-hero__cta"
                    >
                        <BaseButton
                            v-for="cta in actions"
                            :key="cta.label"
                            element="a"
                            :href="cta.url"
                            class="the-hero__button"
                        >
                            {{ cta.label }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import BaseImage from '~/components/BaseImage/BaseImage.vue';
import { useWindowSize } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const localePath = useLocalePath();

const actions = ref([
    {
        label: t('home.cta.agenda'),
        url: localePath('/agenda'),
    },
    {
        label: t('home.cta.visit'),
        url: locale.value === 'nl' ? '/openingstijden-locatie-toegangsprijzen' : '/en/opening-hours-location-ticket-prices',
    },
]);

const props = defineProps({
    themeVariant: {
        type: String,
        required: false,
        default: 'theme-light',
    },
    items: {
        type: Array,
        required: false,
        default: () => [],
    }
});

const { width } = useWindowSize();

const { items } = toRefs(props);

// FIX Hydration class mismatch error: now only works on save
const colorLastItem = computed(() => {
    return width < 768 ? items.value?.[items?.value?.length - 1]?.selectedTheme : '';
});

const themeFirstItem = computed(() => {
    return items.value?.[0]?.selectedTheme;
});


const theme = ref(null);
theme.value = themeFirstItem.value;

const themeImage = ref(items.value?.[0]?.heroImage[0]);

//on hover
const changeThemeHero = ({ selectedTheme ,heroImage }) => {
    theme.value = selectedTheme;
    themeImage.value = heroImage[0];
};

const head = computed(() => {
    return {
        bodyAttrs: {
            'data-theme': theme.value,
            'data-theme-variant': props.themeVariant
        }
    };
});

defineExpose({
    themeFirstItem
});

useHead(head);
</script>

<style lang="less" src="./TheHero.less"></style>

