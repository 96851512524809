<template>
    <div>
        <TemplateBase
            :theme="pageTheme"
        >
            <template #hero>
                <TheHero
                    ref="hero"
                    :items="heroItems"
                />
            </template>
            <DelayHydration>
                <template #default>
                    <div>
                        <BaseSection
                            data-width="page"
                            title-style="secondary"
                        >
                            <template #title>
                                {{ entry.sectionText }}
                            </template>
                            <BaseCards :cards="entry.cards" />
                        </BaseSection>

                        <BaseSection data-width="page">
                            <BaseCTA
                                :call-to-action="entry.callToAction?.[0]"
                            />
                        </BaseSection>
                        <NewsletterCTA
                            :newsletter-cta="entry.newsletterCta?.[0]"
                        />
                    </div>
                </template>
            </DelayHydration>
        </TemplateBase>

        <RelatedContent
            v-if="entry.relevantItems && entry.relevantItems?.length"
            :related-items="entry.relevantItems"
        />
    </div>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/Entry.graphql';

const { variables: defaultVariables }  = useCraftGraphql();

const variables = computed(() => {
    return {
        ...defaultVariables,
        section: 'home',
        uri: null, // We don't need the URI for this query
    };
});

const response = await useAsyncQuery({
    query: EntryQuery,
    variables
});
const { craftEntry: entry } = useCraftPage(response);

const heroItems = computed(() => entry.value?.homeHero?.[0]?.homeHeroEntries);

const hero = ref(null);
const pageTheme = ref(null);

onMounted(() => {
    pageTheme.value = hero.value.themeFirstItem;
});
</script>
